.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}

.header__menu {
  background-color: none;
}

.header__link,
.header__link:hover {
  background-color: none;
  text-decoration: none;
}

.verse__container {
  display: inline-flex;
  position: relative;
  /* background-color: #272727; */
}

.verse__number {
  font-size: 8px;
  position: absolute;
  margin-top: -4px;
  margin-left: -5px;
}

.result__number {
  font-size: "1rem";
  position: relative;
  display: flex;
  margin: "1rem";
}

.result__total {
  display: "flex";
  position: relative;
  margin: "1rem";
}

.verse__text {
  align-items: normal;
  margin: none;
  padding: none;
  text-align: left;
  display: inline;
  width: auto;
  box-shadow: none;
  border: none;
  background-color: transparent;
  font-family: "EB Garamond";
  font-weight: 200;
}

.verse__selected {
  border-left: 1px dashed #fff;
  border-top: 1px dashed #fff;
  border-right: 1px dashed #fff;
}

/* Styles for the login page */
.login-container {
  text-align: center;
  margin-top: 50px;
}

.login-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

/* Modal Styles */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  margin: auto;
  text-align: center;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

/* Buttons inside the modal */
.google-login, .email-login {
  background-color: #4285F4;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  margin: 10px;
  font-size: 16px;
  border-radius: 5px;
}

.google-login:hover, .email-login:hover {
  background-color: #3367d6;
}

.close-modal {
  background-color: #ccc;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
  background: #212121;
  border-radius: 5px;
}

.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  border-radius: 15px;
  background-color: black;
}